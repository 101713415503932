<template>
    <div>
        <h2>Group Settings</h2>
        <b-spinner v-if="!group" />
        <b-card
            v-else
            title="Current Semester"
            sub-title="Set the current active semester for a group."
        >
            <!-- TODO: Add custom semester select component to hide previous semesters we don't care about. -->
            <b-select
                :options="options"
                v-model="settings.current_semester_id"
            ></b-select>
            <b-button
                class="mt-2"
                @click="saveSettings()"
            >
                Save Settings
            </b-button>
            <!-- <b-radio-group :options="[{ text: 'Registration open to everyone', value: 'open'}, { text: 'Registration open for new registrations only', value: 'open-new'}]"></b-radio-group> -->
        </b-card>
        <!--
        <b-card
            title="Teacher Settings"
            sub-title="Settings for teachers."
            class="mt-3"
        >
            <b-radio-group :options="[{ text: 'Registration open to everyone', value: 'open'}, { text: 'Registration open for new registrations only', value: 'open-new'}]"></b-radio-group>
        </b-card>
        -->
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            settings: {
                registration_open: false
            },
            options: []
        };
    },
    computed: mapState(["group"]),
    mounted() {
        this.settings.current_semester_id = (this.group) ? this.group.current_semester_id : null;
        this.getSemesters();
    },
    methods: {
        async getSemesters() {
            const { data: { semesters } } = await this.$api.get("/api/admin/semesters/list");

            if (semesters) {
                this.options = semesters.map(({ label: text, id: value }) => {
                    return { text, value };
                }).slice(0, 5);
            }
        },
        async saveSettings() {
            const { data } = await this.$api.post(
                "/api/group/admin/set-current-semester",
                { semester_id: this.settings.current_semester_id }
            );

            this.$bvToast.toast(data.message, {
                title:   (data.success) ? "Success" : "Error",
                variant: (data.success) ? "success" : "danger",
                autoHideDelay: 5000,
                appendToast: true
            });

            if (data.semester) {
                this.$store.commit("setSemester", data.semester);
            }
        }
    },
    watch: {
        group(group) {
            this.settings.current_semester_id = (group) ? group.current_semester_id : null;
        }
    }
}
</script>